$col-black: #111111;
$col-white: #ffffff;
$col-orange: #97b641; // this one for highlights
$col-boulder: #777777;
$col-gray: #aaaaaa;
$font-bebas: "Bebas Neue", sans-serif;
$font-sauce: "Open Sauce", arial;
$gradient: linear-gradient(
  90deg,
  rgba(139, 218, 108, 0.5) 0%,
  rgba(85, 154, 214, 0.5) 100%
);
