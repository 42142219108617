html {
  font-size: calc(100vw / 1440 * 10);
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include media("<=phone") {
    font-size: calc(100vw / 550 * 10);
  }

  @include media("<=375px") {
    font-size: calc(100vw / 375 * 10);
  }
}

// resets
body {
  font-family: $font-sauce, sans-serif;
  font-weight: 500;
  min-height: 100vh;
  font-size: 1.6rem;
  background: $col-black;
  color: $col-gray;
}

// Locomotive

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100vh;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
  display: none;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* custom bits */

// for styled strikethroughs
@mixin strikethrough {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    height: 1px;
    background: linear-gradient(to right, transparent 49%, #000 50%, transparent 51%);
    transition: background 0.3s ease;
  }
}

.strike {
  position: relative;
  color: #888;
  transition: color 0.3s ease; 

  &::before {
    @include strikethrough;
  }

  &:hover {
    color: #b8b6b6;
    &::before {
      background: linear-gradient(to right, transparent 49%, #ff6600 50%, transparent 51%);
    }
  }

  &:not(:hover) {
    color: #888;
    &::before {
      background: linear-gradient(to right, transparent 49%, #000 50%, transparent 51%);
    }
  }
}

.bold_custom {
  font-weight: 700;
}

// for colourful texts
.random_gradient {
  display: inline-block;
  position: relative;

  & {
    background: linear-gradient(45deg, #5fd393, #42a5e7);
    background-size: 200% 200%;
    animation: gradientAnimation 7s ease-in-out infinite;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}